/* exo-200 - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 200;
  src: local(''),
       url('../forum_mg/fonts/exo-v11-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../forum_mg/fonts/exo-v11-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-regular - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../forum_mg/fonts/exo-v11-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../forum_mg/fonts/exo-v11-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-600 - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('../forum_mg/fonts/exo-v11-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../forum_mg/fonts/exo-v11-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* exo-800 - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 800;
  src: local(''),
       url('../forum_mg/fonts/exo-v11-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../forum_mg/fonts/exo-v11-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
