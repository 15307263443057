@mixin mg-sectiontitle() {
  text-align: center;
  border-bottom: none;
  margin: $mg-space;
  & > * {
    color: get-color($mg-theme, 'dark');
    border-bottom: none;
    font-size: get-fontsize('xxl');
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
}

.mg-sectiontitle {
  .head,
  .headline {
    @include mg-sectiontitle();
  }
}
