.mg-slider--row {
  background: #fff;
  @media (min-width: $screen-sm-min) {
    padding: 0 !important;
  }
  & > div {
    padding: 0;
  }
}

.mg-slider--element {
  margin-bottom: 0;
  @media (min-width: $screen-sm-min) {
    .background {
      display: none;
    }
    .foreground {
      strong,
      .subcaption small {
        color: #fff !important;
        display: inline-block;
        background: transparentize($main-theme--dark, 0.15);
        opacity: 0.85;
       }
      strong {
        margin-bottom: 1rem !important;
      }
      .subcaption small {

      }
    }
    .caption {
      &.top {
        top: 15% !important;
      }
      &.bottom {
        bottom: 15% !important;
      }
      &.right {
        right: 0 !important;
        &.foreground strong,
        &.foreground .subcaption small {
          padding: 1.5rem 5rem 1.5rem 2rem !important;
          border-radius: 1rem 0 0 1rem;
        }
      }
      &.left {
        left: 0 !important;
        &.foreground strong,
        &.foreground .subcaption small {
          padding: 1.5rem 2rem 1.5rem 5rem !important;
          border-radius: 0 1rem 1rem 0;
        }
      }
    }
  }
}
