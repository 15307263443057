.mg-_highlight-reel {
  .text-overlay {
    position: relative;
    min-height: 0 !important;
    background: transparent;
    margin-top: 1rem;
    & > * {
      color: black !important;
      text-shadow: none !important;
      }
  }
}