.mg-contactform {
  .sky-form {
    border: none;
    padding: 0;
    fieldset {
      background: transparent;
      margin: $mg-space--xsmall * -2;
      display: flex;
      flex-wrap: wrap;
      section {
        margin: $mg-space--xsmall;
        &:first-of-type,
        &:nth-of-type(2){
          flex-basis: calc(50% - #{$mg-space--xsmall * 2});
        }
        &:nth-of-type(3){
          flex-basis: 100%;
        }
        label {
          display: none;
        }
        input,
        textarea {
          border: none;
          border-radius: 0.5em;
        }
      }
    }
    footer {
      text-align: center;
      padding: 0;
      border: none;
      .note {
        display: none;
        // text-align: right;
        // padding-top: 2em;
      }
      .submitbutton {
        @include mg-button();
        margin-top: $mg-space--small;
      }
      .resetbutton {
        display: none;
      }
    }
  }
}

.mg-signupform {
  .sky-form {
    border: none;
    padding: 0;
    & > fieldset {
      background: transparent;
      padding: 0;
      margin: calc(-1 * $mg-space--xsmall);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      section {
        flex-basis: 100%;
        margin: $mg-space--xsmall;
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7),
        &:nth-of-type(8) {
          flex-basis: calc(25% - #{$mg-space--xsmall * 2});
        }
        &:nth-of-type(9),
        &:nth-of-type(10),
        &:nth-of-type(11) {
          flex-basis: calc(50% - #{$mg-space--xsmall * 2});
        }
        &:nth-of-type(2) {
          flex-basis: calc(33% - #{$mg-space--xsmall * 2});
        }
        &:nth-of-type(1) {
          flex-basis: calc(66% - #{$mg-space--xsmall * 2});
        }
      }
    }
  }
}