.mg_footer {
  padding-top: 0;
  background: $mg-general--dark;
  & > .container {
    padding-top: $mg-space;
    padding-bottom: $mg-space;
  }
  &--logos {
    & > div {
      display: flex;
      align-items: flex-end;
    }
    .ap-section {
      width: 10%;
      margin-right: 25px;
    }
  }
}
