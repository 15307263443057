@mixin mg-button() {
  padding: 0.5em 1em;
  border-radius: $mg-space--xxsmall;
  color: #fff;
  background-color: get-color($mg-theme);
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: get-color($mg-theme, 'dark');
    text-decoration: none;
  }
}

.mg-button-more {
  text-align: center;
  margin: $mg-space;
  a {
    @include mg-button();
    display: inline-block;
  }
}
