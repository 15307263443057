/*############################################*/
/*
/*  GLOBAL
/*
/*####################################s########*/
div.tx-webbasys-pi1 img{
  max-width: 250px;
}
@media (max-width:767px){
    .tx-webbasys-pi1 a[data-toggle="modal"] {
    pointer-events: none;
    }
}


#content .hauptseite_clickpfad {
  display: none;
}

.pflicht,
.validierung {
  color: $pflicht;
  font-weight: bold;
}

h3.warnungpflichtfelder {
    color: $pflicht;
}

ul.warnungpflichtfelder {
  display: none;
  color: $pflicht;
}

hr {
  border-top: 1px solid #C8C8DC;
  margin-top: 10px;
}

.kw-buttonleiste,
.kw-paginationleiste,
.kw-blaetternleiste {
  margin-bottom: 15px;

}

.kw-gradient{
 // .gradient(#fcfefe,#c8c8dc, #fcfefe);
}


.kw-button {
  // .kw-gradient;
   color: #164e82;

  margin-left: 10px;
  padding: 7px 10px 7px 10px;
  text-decoration: none !important;
  border: 1px solid #bebebe;
}

.kw-paginationleiste .kw-button,
.kw-blaetternleiste  .kw-button{
    margin-left: 0px;
}

// @media (min-width:992px){
// .kw-buttonleiste .kw-button i.fa{
//   visibility: hidden;
// }

@media (max-width: 449px){
.kw-buttonleiste a {
    display: block;
    margin-bottom: 15px;
    }
}

.kw-buttonleiste .kw-button  i.fa{
  margin-right: 2px;
}

.kw-buttonleiste .kw-button:hover i.fa {
  visibility: visible;
}

.kw-buttonleiste .kw-button span.kw-button-link {
  padding-left: 4px;
}

/*############################################*/
/*
/*Darstellung von KW-Plugins in den Sidebars                                     */
/*
/*############################################*/

div.sidebar_left div.kw-plugin,
div.sidebar_right div.kw-plugin {
  padding: 15px;
}

div.sidebar_left div.hauptseite_status,
div.sidebar_right div.hauptseite_status {
  display: none;
}

.kuferwebcontent {
  margin-top:30px;

}

/*############################################*/
/*
/*Anmeldung                                     */
/*
/*############################################*/

.form-control {
  margin-bottom: 10px;
}

.kw-anmlabel {
  height: 34px;
  padding-top: 10px;
}

label[for^="tedit_bankart"] {
  margin-bottom: 20px;
}

.sicherheitsabfrage {
  margin-left: -15px;
}

label.krit1 {
  font-weight: normal;
}

label.krit1.pflicht {
  font-weight: bold;
}

label[for^="tedit_bankart"]{
  margin-right: 1em;
}

/*tabelen bei weiteren personen*/
table.kw-anm-plus-person> thead > tr {
    // .kw-gradient;
    color: $link-farbe;
}


/*############################################*/
/*
/*  Sicht 261 Kategorienübersicht
/*
/*############################################*/

.kw-unterkategorien
#accordion #accordion {
  margin-left: 20px;
}
.kw-unterkategorien a{
    font-size:15px;
}
.kw-unterkategorien {
  margin-left: 50px;
}


.kw-kateg-liste-katicon {
  margin-right: 15px;
  height: 35px;
  width: 35px;
  background-size: contain;
  background-repeat: no-repeat;
}

/*katicons einbinden*/
.kateg5 .kw-kateg-liste-katicon{
background-image: url("../img/katicons/Gesellschaft.png");
}
.kateg8 .kw-kateg-liste-katicon{
background-image: url("../img/katicons/Kultur.png");
}
.kateg14 .kw-kateg-liste-katicon{
background-image: url("../img/katicons/Gesundheit.png");
}
.kateg18 .kw-kateg-liste-katicon{
background-image: url("../img/katicons/Sprachen.png");
}
.kateg28 .kw-kateg-liste-katicon{
background-image: url("../img/katicons/Beruf.png");
}
.kateg1000 .kw-kateg-liste-katicon{
background-image: url("../img/katicons/Grundbildung.png");
}

span.kw_katue_moreInfo {
  cursor: pointer;
}

.kw-kategorie {
  margin-bottom: 5px;
}


.kw-subkat {
  margin-top: 10px;
}


/*############################################*/
/*
/* Sicht 12 - Kursübersicht */
/*
/*############################################*/

.kw-kursuebersicht{
  padding-top:0px;
  padding-left: 0px;
  padding-right:0px;
  margin-left: 15px;
}
.kw-kursuebersicht .kw-kursheader{
    background-size: auto 90px;
    background-position: center right;
    background-repeat: no-repeat;
}
.kw-kursuebersicht .kw-kursheader.kateg5 {
    background-image: url("../img/katicons/bg-gesellschaft.jpg");
}
.kw-kursuebersicht .kw-kursheader.kateg8 {
    background-image: url("../img/katicons/bg-kultur.jpg");
}
.kw-kursuebersicht .kw-kursheader.kateg14 {
    background-image: url("../img/katicons/bg-gesundheit.jpg");
}
.kw-kursuebersicht .kw-kursheader.kateg18 {
    background-image: url("../img/katicons/bg-sprachen.jpg");
}
.kw-kursuebersicht .kw-kursheader.kateg28 {
    background-image: url("../img/katicons/bg-beruf.jpg");
}
.kw-kursuebersicht .kw-kursheader > h3{
    width: 83%;
}

ul.seiteblaettern {
	margin-top:0;

}
/*############################################*/
/*
/*   Sicht 13 - Kursdetails */
/*
/*############################################*/

img.kw-kursfoto {
  max-width: 400% !important;
  margin-bottom: 4px;
  width:100%;
}


@media (min-width: 750px) {
  img.kw-kursfoto {
    height: 160px;
    width: auto;
    margin-bottom: 4px;
  }
}


.btn_leiste span.glyphicon {
  color: transparent;
  padding-right: 5px;
  color: transparent;
}

.btn_leiste:hover span.glyphicon {
  color: #371964;
  text-shadow: 1px 1px 1px #bebebe;
}

.kw-kursdetails ul.nav-pills {
  background: #fcfefe;
  background: -moz-linear-gradient(top,  #fcfefe 0%, #c8c8dc 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#fcfefe), color-stop(100%,#c8c8dc));
  background: -webkit-linear-gradient(top,  #fcfefe 0%,#c8c8dc 100%);
  background: -o-linear-gradient(top,  #fcfefe 0%,#c8c8dc 100%);
  background: -ms-linear-gradient(top,  #fcfefe 0%,#c8c8dc 100%);
  background: linear-gradient(to bottom,  #fcfefe 0%,#c8c8dc 100%);
  margin-top: 20px;
  font-weight: bold;
}

.hauptseite_kurse .nav-pills li a {
  text-decoration: none !important;
}

.hauptseite_kurse .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: #fff;
  text-decoration: none !important;
  background: #002242;
  background: -moz-linear-gradient(top,#002242 0%,#164e82 100%);
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#002242), color-stop(100%,#164e82));
  background: -webkit-linear-gradient(top,#002242 0%,#164e82 100%);
  background: -o-linear-gradient(top,#002242 0%,#164e82 100%);
  background: -ms-linear-gradient(top,#002242 0%,#164e82 100%);
  background: linear-gradient(to bottom,#002242 0%,#164e82 100%);
  border-radius: 0px !important;
}

.tab-content {
  padding: 0px;
  border: none;
}

.label-danger {
  font-size: 15px;
  color: #d9534f;
  background-color: transparent;
}

.kw-buttonleiste .kw-ampelmeldung{
    padding: 8px;
    margin-top:15px;
   }


/* Sicht 179 - Kurs-Kursortliste */
.kw-kursortliste{
    list-style-type:none;
    padding-left: 0;
}

/* Sicht 180 - Kurs-Dokumentenliste */
a.dokumente {
    font-size: 14px;
}

/*############################################*/
/*
/*   Dozenten alle Sichten
/*
/*############################################*/

/* Sicht 236 - Dozentenübersicht Pagination */
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}


.pagination > li > span {
  color: #000000;
}


/* Sicht 218 - Dozentenübersicht gruppiert */
.kw-dozuebersicht-img {
  width: 100px;
}

/* Sicht 50 - Kurssuche */
.kw-kurssuche-label {
  margin-top: 10px;
}

/* Sicht 223 - Dozenten-Kursliste */
.kw-dozkursliste{
list-style-type:none;
}

/* Sicht 220 - Dozenten-Qualifikationaliste */
.kw-doz-qualiste{
list-style-type:none;
padding-left: 0;
}

/*############################################*/
/*
/*  KuferWEB Newsletter
/*
/*############################################*/

ul#nl_interessensgebiete input[type="checkbox"] {
  margin: 0px 10px 0px 0px;
}

input#kw-submit-form {
  display: none;
}


.news-list-browse {
    display: none;
}

.nl_anmeldung  ul.warnungpflichtfelder {
  display: block;
  color: #E1000F;
}
/*############################################*/
/*
/*  KuferWEB Stichwortliste
/*
/*############################################*/

li.pagination.kw-letterlist {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}


/*############################################*/
/*
/*  Bilder
/*
/*############################################*/

.panelimg {
  width: 100%;
}

.img-circle img {

}

.img-text img {

}


/*############################################*/
/*
/*  Warenkorb
/*
/*############################################*/

div.kw_wk_button {
  /*width: 40px;
  height: 40px;
  position: relative;
  right: 149px;
  top: 23px;
  background-color: $vhs-blau;
  margin-top: 26px;*/
  float: right;
  display: block !important;
  width: 90px;
    height: auto;
    position: relative;
    background-color: transparent;
    margin-top: 0px;
  color: #FFFFFF;
  margin-top: 20px;
}


  div.kw-wk-text {
    padding-top: 11px;
  }

  div.kw-wk-count {
    float: right;
  font-size: 3em;
  font-weight: bold;
  }

@media (min-width: 768px) {
  div.kw_wk_button {
  display: block !important;
  width: 100px;
    height: auto;
    position: relative;
    background-color: transparent;
    margin-top: 0px;
  color: #FFFFFF;
  margin-top: 20px;
  }

  div.kw-wk-text {
    padding-top: 11px;
  }

  div.kw-wk-count {
    float: right;
  font-size: 3em;
  font-weight: bold;
  }

}

div.kw_wk_counter {
  float: right;
  margin-top: -17px;
  margin-right: -17px;
}

div.kw_wk_counter span.badge {
  background-color: $vhs-rot;
  height: 20px;
  padding-top: 3px;
}

div.kw_wk_button span.glyphicon-shopping-cart,
div.header span.glyphicon-info-sign {
  color: #FFFFFF;
  position: absolute;
  top: 8px;
  left: 9px;
  font-size: 20px;
}

/* Sicht 59 - Warenkorb */

.kw-wk-kurs {
  padding-left: 0px;
  list-style-type: none;
}

.kw-wk hr {
  margin-bottom: -7px;
}

.kw-wk .pull-left {
padding-right: 20px;
}

/*############################################*/
/*
/* Sicht 227 - Veranstaltungskalender */
/*
/*############################################*/



.eine a,
.mehrere a{
  padding: 3px 1px 2px 0;
  display: block;
  text-align: center;
  background-color: $veranstaltungskalender_eine;
}

.mehrere a{
  background-color: $veranstaltungskalender_mehrere;
}

/*
.kw_kalender thead{
  background: lighten($vhs-gelb, 25%);
  opacity: 0.6;
}
*/

.kw-kalender-kalender {

}

.kw_kalender .samstag,
.kw_kalender .sonntag
{
  /*background:$veranstaltungskalender_woende;*/
  opacity: 0.6;
}

.kw_kalender td,
{
  box-sizing: border-box;
  text-align: center;
}

.kw_kalender th,
/*.kw_kalender tr,*/
.kw_kalender td {
  border: 0px !important;
  padding: 3px;
}

div.kw_kalender tr {
  /*border-bottom: 1px solid 123456;*/
}

div.kw_kalender_bg {
  background: $veranstaltungskalender_bg;
  height: 300px;
  width: 284px;
  margin-bottom: -302px;
  margin-left: 20px;
  border:1px solid #CCC;
  border-radius:4px;
}

.kw_kalender {
  width: 100%;
  max-width: 270px;
  text-align: center;
  margin-bottom: 40px;
  color: $veranstaltungskalender_text;
  margin-left: 30px;

}

.kw_kalender table {
  margin-bottom: 10px !important;
}

.well{
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #fff;
  border: 0;
  border-radius: 4px;
  box-shadow: none;
}

@media (min-width: 768px) {

  div.kw_kalender_bg {
    margin-left: -20px;
  }

  .kw_kalender{
    width: 340px;
    margin-left: -18px;
  }

  .kw_kalender table{
    max-width: 250px;
    margin-left: 15px;
  }

  .kw_kalender h3,
  .kw_kalender > p{
    margin-left: 8px;
    width: 250px;
  }
}

#content_right{
  margin-top: 20px;
}

div.kw-kalender-head {
  width: 25px;
  height: 25px;
  background-color: $veranstaltungskalender_wotage;
  overflow: hidden;
  color: #FFFFFF;
  font-size: 11px;
  padding-top: 5px;
  text-align: center;
}

.kw-kalender-btn-for {
  font-size: 10px;
  padding-left: 5px;
  padding-right: 2px;
  margin-left: 15px;
}

.kw-kalender-btn-back {
  font-size: 10px;
  padding-left: 3px;
  margin-right: 15px;
}

.kw_kalender {
  /*border: 1px solid #DDDDDD;*/
  margin-left: 1px;
  width: 262px;
}

.kw_kalender h3, .kw_kalender > p {
  margin-left: 5px;
}

.kw-kalender-kalender {
  margin-left: -8px;
}

div.kw-kalender-head {
  background-color: transparent;
  color: #00285A;
  font-weight: normal;
  font-size: 13px;
}

/*############################################*/
/*
/* Menüs                                      */
/*
/*############################################*/

 .sidebar_left .kw-verlauf h4 {
 font-size: 1em;

 }

/*############################################*/
/*
/*Legende                                       */
/*
/*############################################*/
.legend.list-group-item {
    border: none;
    padding-left: 0;
}

/*############################################*/
/*
/*Legende                                       */
/*
/*############################################*/
.sidebarshoppingcart {
color:#72c02c;
font-size:24px;
}
/*############################################*/
/*
/*Frei definierbare Scihten                   */
/*
/*############################################*/

.kw_navmenu_topkategorien{
    list-style-type: none;
    padding-left:0px;
}

.kw_navmenu_topkategorien li{
    padding: 6px 0px;
}

.statusadresse {display:none;}

.kategorien_bg {
 padding:15px 15px 0 15px;
 background: #0a4157;
}


/* KATEGORIE BACKGROUND */
.religion_spiritualitaet .kategorielinkbg {
  background: #925079 !important;
}

.qualifikation_fortbildung .kategorielinkbg {
  background:#e25b53;
}

.leben_beziehungen .kategorielinkbg {
  background:#ea988b;
}

.kultur_kreativitaet .kategorielinkbg {
  background:#f39200;
}

.gesellschaft_politik .kategorielinkbg {
  background:#fbbb24;
}

.familien_kinder .kategorielinkbg {
  background:#c3ae0e;
}


.gesundheit_fitness .kategorielinkbg {
  background:#6ea63d;
}

/*############################################*/
/*
/*  GLOBAL
/*
/*####################################s########*/
body.religion_spiritualitaet .header,
body.religion_spiritualitaet .intro_section {
 background-image: url('../img/icon_header_bg/FMG9783_Header_Icons_LA1_lila.png'), linear-gradient(to right, #653653 0%, #925079 50%, #653653 100%) !important;
 background-size: 133%;
}

body.qualifikation_fortbildung  .header,
body.qualifikation_fortbildung  .intro_section {
 background-image: url('../img/icon_header_bg/FMG9783_Header_Icons_LA1_rot.png'), linear-gradient(to right, #ac4740 0%, #e35b54 50%, #ac4740 100%) !important;
 background-size: 133%;
}

body.leben_beziehungen  .header,
body.leben_beziehungen  .intro_section {
 background-image: url('../img/icon_header_bg/FMG9783_Header_Icons_LA1_rosa.png'), linear-gradient(to right, #a86d62 0%, #eb988b 50%, #a86d62 100%) !important;
 background-size: 133%;
}

body.kultur_kreativitaet .header,
body.kultur_kreativitaet .intro_section {
 background-image: url('../img/icon_header_bg/FMG9783_Header_Icons_LA1_orange.png'), linear-gradient(to right, #a76700 0%, #f39200 50%, #a76700 100%) !important;
 background-size: 133%;
}

body.gesellschaft_politik  .header,
body.gesellschaft_politik  .intro_section {
 background-image: url('../img/icon_header_bg/FMG9783_Header_Icons_LA1_gelb.png'), linear-gradient(to right, #b68b1c 0%, #fbbb25 50%, #b68b1c 100%) !important;
 background-size: 133%;
}

body.familien_kinder .header,
body.familien_kinder .intro_section {
 background-image: url('../img/icon_header_bg/FMG9783_Header_Icons_LA1_olive.png'), linear-gradient(to right, #776d07 0%, #c3af0e 50%, #776d07 100%) !important;
 background-size: 133%;
}

body.gesundheit_fitness .header,
body.gesundheit_fitness .intro_section {
 background-image: url('../img/icon_header_bg/FMG9783_Header_Icons_LA1_gruen.png'), linear-gradient(to right, #487028 0%, #6fa63e 50%, #487028 100%) !important;
 background-size: 133%;
}


/*
Forum (Blau)
Standard: #0086a6
Dunkel: #004056
Religion & Spiritualität (violett)
Standard: #925079
Dunkel: #653652
Qualifikation & Fortbildung (rot)
Standard: #e25b53
Dunkel: #ac463f
Leben & Beziehungen (rosa)
Standard: #ea988b
Dunkel: #a86d62
Kultur & Kreativität (orange)
Standard: #f39200
Dunkel: #a66700
Gesellschaft & Politik (gelb)
Standard: #fbbb24
Dunkel: #b58a1b
Familien & Kinder (olive)
Standard: #c3ae0e
Dunkel: #776d07
Gesundheit & Fitness (grün)
Standard: #6ea63d
Dunkel: #486f28
*/

/*############################################*/
/*
/* Farben der Links / Pagination in der Kurübersicht / den Kursdetails   (Qualifikation / Fortbildung)
/*
/*####################################s########*/
.pagination li a:hover,
.pagination > a:hover,
.pagination > span:hover,
.pagination > a:focus,
.pagination > span:focus ,
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    border-color: #0086a6 !important;
    background-color: #0086a6 !important;
	color:#fff;
}

.warenkorbsidebarlink,
.sidebarshoppingcart,
.kuferwebcontent .kw-kursuebersicht a,
.kuferwebcontent .kw-kursdetails a {
  color:#0086a6 !important;
}

.warenkorbsidebarlink:hover,
.sidebarshoppingcart:hover,
.kuferwebcontent .kw-kursuebersicht a:hover,
.kuferwebcontent .kw-kursdetails a:hover {
  color:#004056 !important;
}

ul.nav-pills li.active a {
	color:#fff !important;
}

.kw-kursdetails hr {
	margin:15px 0 !important;
}

/*############################################*/
/*
/* Farben der Links / Pagination in der Kurübersicht / den Kursdetails   (Qualifikation / Fortbildung)
/*
/*####################################s########*/
body.qualifikation_fortbildung .pagination li a:hover,
body.qualifikation_fortbildung .pagination > a:hover,
body.qualifikation_fortbildung .pagination > span:hover,
body.qualifikation_fortbildung .pagination > a:focus,
body.qualifikation_fortbildung .pagination > span:focus ,
body.qualifikation_fortbildung .pagination > .active > a,
body.qualifikation_fortbildung .pagination > .active > span,
body.qualifikation_fortbildung .pagination > .active > a:hover,
body.qualifikation_fortbildung .pagination > .active > span:hover,
body.qualifikation_fortbildung .pagination > .active > a:focus,
body.qualifikation_fortbildung .pagination > .active > span:focus {
    border-color: #e25b53 !important;
    background-color: #e25b53 !important;
	color:#fff;
}

body.qualifikation_fortbildung .warenkorbsidebarlink,
body.qualifikation_fortbildung .sidebarshoppingcart,
body.qualifikation_fortbildung .kuferwebcontent .kw-kursuebersicht a,
body.qualifikation_fortbildung .kuferwebcontent .kw-kursdetails a {
  color:#e25b53 !important;
}

body.qualifikation_fortbildung .warenkorbsidebarlink:hover,
body.qualifikation_fortbildung .sidebarshoppingcart:hover,
body.qualifikation_fortbildung .kuferwebcontent .kw-kursuebersicht a:hover,
body.qualifikation_fortbildung .kuferwebcontent .kw-kursdetails a:hover {
 color:#ac463f !important;
}

body.qualifikation_fortbildung ul.nav-pills li.active a {
	color:#fff !important;
}

/*############################################*/
/*
/* Farben der Links / Pagination in der Kurübersicht / den Kursdetails   (Religion & Spiritualität)
/*
/*####################################s########*/
body.religion_spiritualitaet .pagination li a:hover,
body.religion_spiritualitaet .pagination > a:hover,
body.religion_spiritualitaet .pagination > span:hover,
body.religion_spiritualitaet .pagination > a:focus,
body.religion_spiritualitaet .pagination > span:focus ,
body.religion_spiritualitaet .pagination > .active > a,
body.religion_spiritualitaet .pagination > .active > span,
body.religion_spiritualitaet .pagination > .active > a:hover,
body.religion_spiritualitaet .pagination > .active > span:hover,
body.religion_spiritualitaet .pagination > .active > a:focus,
body.religion_spiritualitaet .pagination > .active > span:focus {
    border-color: #925079 !important;
    background-color: #925079 !important;
    color:#fff;
}

body.religion_spiritualitaet .warenkorbsidebarlink,
body.religion_spiritualitaet .sidebarshoppingcart,
body.religion_spiritualitaet .kuferwebcontent .kw-kursuebersicht a,
body.religion_spiritualitaet .kuferwebcontent .kw-kursdetails a {
  color:#925079 !important;
}

body.religion_spiritualitaet .warenkorbsidebarlink:hover,
body.religion_spiritualitaet .sidebarshoppingcart:hover,
body.religion_spiritualitaet .kuferwebcontent .kw-kursuebersicht a:hover,
body.religion_spiritualitaet .kuferwebcontent .kw-kursdetails a:hover {
 color:#653652 !important;
}

body.religion_spiritualitaet ul.nav-pills li.active a {
	color:#fff !important;
}

/*############################################*/
/*
/* Farben der Links / Pagination in der Kurübersicht / den Kursdetails   (Qualifikation / Fortbildung)
/*
/*####################################s########*/
body.qualifikation_fortbildung .pagination li a:hover,
body.qualifikation_fortbildung .pagination > a:hover,
body.qualifikation_fortbildung .pagination > span:hover,
body.qualifikation_fortbildung .pagination > a:focus,
body.qualifikation_fortbildung .pagination > span:focus ,
body.qualifikation_fortbildung .pagination > .active > a,
body.qualifikation_fortbildung .pagination > .active > span,
body.qualifikation_fortbildung .pagination > .active > a:hover,
body.qualifikation_fortbildung .pagination > .active > span:hover,
body.qualifikation_fortbildung .pagination > .active > a:focus,
body.qualifikation_fortbildung .pagination > .active > span:focus {
    border-color: #e25b53 !important;
    background-color: #e25b53 !important;
	color:#fff;
}

body.qualifikation_fortbildung .warenkorbsidebarlink,
body.qualifikation_fortbildung .sidebarshoppingcart,
body.qualifikation_fortbildung .kuferwebcontent .kw-kursuebersicht a,
body.qualifikation_fortbildung .kuferwebcontent .kw-kursdetails a {
  color:#e25b53 !important;
}

body.qualifikation_fortbildung ul.navpills li.active a {
	color:#fff !important;
}

/*############################################*/
/*
/* Farben der Links / Pagination in der Kurübersicht / den Kursdetails   (Leben & Beziehungen)
/*
/*####################################s########*/
body.leben_beziehungen .pagination li a:hover,
body.leben_beziehungen .pagination > a:hover,
body.leben_beziehungen .pagination > span:hover,
body.leben_beziehungen .pagination > a:focus,
body.leben_beziehungen .pagination > span:focus ,
body.leben_beziehungen .pagination > .active > a,
body.leben_beziehungen .pagination > .active > span,
body.leben_beziehungen .pagination > .active > a:hover,
body.leben_beziehungen .pagination > .active > span:hover,
body.leben_beziehungen .pagination > .active > a:focus,
body.leben_beziehungen .pagination > .active > span:focus {
    border-color: #ea988b !important;
    background-color: #ea988b !important;
    color:#fff;
}

body.leben_beziehungen .warenkorbsidebarlink,
body.leben_beziehungen .sidebarshoppingcart,
body.leben_beziehungen .kuferwebcontent .kw-kursuebersicht a,
body.leben_beziehungen .kuferwebcontent .kw-kursdetails a {
  color:#ea988b !important;
}

body.leben_beziehungen .warenkorbsidebarlink:hover,
body.leben_beziehungen .sidebarshoppingcart:hover,
body.leben_beziehungen .kuferwebcontent .kw-kursuebersicht a:hover,
body.leben_beziehungen .kuferwebcontent .kw-kursdetails a:hover {
 color:#a86d62 !important;
}

body.leben_beziehungen ul.nav-pills li.active a {
	color:#fff !important;
}

/*############################################*/
/*
/* Farben der Links / Pagination in der Kurübersicht / den Kursdetails   (Kultur / Kreativität)
/*
/*####################################s########*/
body.kultur_kreativitaet .pagination li a:hover,
body.kultur_kreativitaet .pagination > a:hover,
body.kultur_kreativitaet .pagination > span:hover,
body.kultur_kreativitaet .pagination > a:focus,
body.kultur_kreativitaet .pagination > span:focus ,
body.kultur_kreativitaet .pagination > .active > a,
body.kultur_kreativitaet .pagination > .active > span,
body.kultur_kreativitaet .pagination > .active > a:hover,
body.kultur_kreativitaet .pagination > .active > span:hover,
body.kultur_kreativitaet .pagination > .active > a:focus,
body.kultur_kreativitaet .pagination > .active > span:focus {
    border-color: #f39200 !important;
    background-color: #f39200 !important;
    color:#fff;
}

body.kultur_kreativitaet .warenkorbsidebarlink,
body.kultur_kreativitaet .sidebarshoppingcart,
body.kultur_kreativitaet .kuferwebcontent .kw-kursuebersicht a,
body.kultur_kreativitaet .kuferwebcontent .kw-kursdetails a {
  color:#f39200 !important;
}

body.kultur_kreativitaet .warenkorbsidebarlink:hover,
body.kultur_kreativitaet .sidebarshoppingcart:hover,
body.kultur_kreativitaet .kuferwebcontent .kw-kursuebersicht a:hover,
body.kultur_kreativitaet .kuferwebcontent .kw-kursdetails a:hover {
 color:#a66700 !important;
}

body.kultur_kreativitaet ul.nav-pills li.active a {
	color:#fff !important;
}

/*############################################*/
/*
/* Farben der Links / Pagination in der Kurübersicht / den Kursdetails   (Gesellschaft / Politik)
/*
/*####################################s########*/
body.gesellschaft_politik .pagination li a:hover,
body.gesellschaft_politik .pagination > a:hover,
body.gesellschaft_politik .pagination > span:hover,
body.gesellschaft_politik .pagination > a:focus,
body.gesellschaft_politik .pagination > span:focus ,
body.gesellschaft_politik .pagination > .active > a,
body.gesellschaft_politik .pagination > .active > span,
body.gesellschaft_politik .pagination > .active > a:hover,
body.gesellschaft_politik .pagination > .active > span:hover,
body.gesellschaft_politik .pagination > .active > a:focus,
body.gesellschaft_politik .pagination > .active > span:focus {
    border-color: #fbbb24 !important;
    background-color: #fbbb24 !important;
    color:#fff;
}

body.gesellschaft_politik .warenkorbsidebarlink,
body.gesellschaft_politik .sidebarshoppingcart,
body.gesellschaft_politik .kuferwebcontent .kw-kursuebersicht a,
body.gesellschaft_politik .kuferwebcontent .kw-kursdetails a {
  color:#fbbb24 !important;
}

body.gesellschaft_politik .warenkorbsidebarlink:hover,
body.gesellschaft_politik .sidebarshoppingcart:hover,
body.gesellschaft_politik .kuferwebcontent .kw-kursuebersicht a:hover,
body.gesellschaft_politik .kuferwebcontent .kw-kursdetails a:hover {
 color:#b58a1b !important;
}

body.gesellschaft_politik ul.nav-pills li.active a {
	color:#fff !important;
}

/*############################################*/
/*
/* Farben der Links / Pagination in der Kurübersicht / den Kursdetails   (Familein & Kinder)
/*
/*############################################*/
body.familien_kinder .pagination li a:hover,
body.familien_kinder .pagination > a:hover,
body.familien_kinder .pagination > span:hover,
body.familien_kinder .pagination > a:focus,
body.familien_kinder .pagination > span:focus ,
body.familien_kinder .pagination > .active > a,
body.familien_kinder .pagination > .active > span,
body.familien_kinder .pagination > .active > a:hover,
body.familien_kinder .pagination > .active > span:hover,
body.familien_kinder .pagination > .active > a:focus,
body.familien_kinder .pagination > .active > span:focus {
    border-color: #c3ae0e !important;
    background-color: #c3ae0e !important;
    color:#fff;
}

body.familien_kinder .warenkorbsidebarlink,
body.familien_kinder .sidebarshoppingcart,
body.familien_kinder .kuferwebcontent .kw-kursuebersicht a,
body.familien_kinder .kuferwebcontent .kw-kursdetails a {
  color:#c3ae0e !important;
}

body.familien_kinder .warenkorbsidebarlink:hover,
body.familien_kinder .sidebarshoppingcart:hover,
body.familien_kinder .kuferwebcontent .kw-kursuebersicht a:hover,
body.familien_kinder .kuferwebcontent .kw-kursdetails a:hover {
 color:#776d07 !important;
}

body.familien_kinder ul.nav-pills li.active a {
	color:#fff !important;
}

/*############################################*/
/*
/* Farben der Links / Pagination in der Kurübersicht / den Kursdetails   (Gesundheit & Fitness)
/*
/*####################################s########*/
body.gesundheit_fitness .pagination li a:hover,
body.gesundheit_fitness .pagination > a:hover,
body.gesundheit_fitness .pagination > span:hover,
body.gesundheit_fitness .pagination > a:focus,
body.gesundheit_fitness .pagination > span:focus ,
body.gesundheit_fitness .pagination > .active > a,
body.gesundheit_fitness .pagination > .active > span,
body.gesundheit_fitness .pagination > .active > a:hover,
body.gesundheit_fitness .pagination > .active > span:hover,
body.gesundheit_fitness .pagination > .active > a:focus,
body.gesundheit_fitness .pagination > .active > span:focus {
    border-color: #6ea63d !important;
    background-color: #6ea63d !important;
    color:#fff;
}

body.gesundheit_fitness .warenkorbsidebarlink,
body.gesundheit_fitness .sidebarshoppingcart,
body.gesundheit_fitness .kuferwebcontent .kw-kursuebersicht a,
body.gesundheit_fitness .kuferwebcontent .kw-kursdetails a {
  color:#6ea63d !important;
}

body.gesundheit_fitness .warenkorbsidebarlink:hover,
body.gesundheit_fitness .sidebarshoppingcart:hover,
body.gesundheit_fitness .kuferwebcontent .kw-kursuebersicht a:hover,
body.gesundheit_fitness .kuferwebcontent .kw-kursdetails a:hover {
 color:#486f28 !important;
}

body.gesundheit_fitness ul.nav-pills li.active a {
	color:#fff !important;
}

/*############################################*/
/*
/*KATEGORIE PUNKTE HOVER BACKGROUND*/
/*
/*####################################s########*/
.religion_spiritualitaet .kategorielinkbg:hover,
.religion_spiritualitaet .kategaktiv1  {
  background: #4e4968 !important;
}

.qualifikation_fortbildung .kategorielinkbg:hover,
.qualifikation_fortbildung .kategaktiv1 {
  background:#764e55;
}

.leben_beziehungen .kategorielinkbg:hover,
.leben_beziehungen  .kategaktiv1 {
  background:#7a6d71;
}

.kultur_kreativitaet .kategorielinkbg:hover,
.kultur_kreativitaet .kategaktiv1 {
  background:#7f6a2b;
}

.gesellschaft_politik .kategorielinkbg:hover,
.gesellschaft_politik .kategaktiv1 {
  background:#837e3d;
}

.familien_kinder .kategorielinkbg:hover,
.familien_kinder .kategaktiv1 {
  background:#677832;
}

.gesundheit_fitness .kategorielinkbg:hover,
.gesundheit_fitness .kategaktiv1 {
  background:#3c744a;
}

/*############################################*/
/*
/*KATEGORIE PUNKTE HOVER BACKGROUND*/
/*############################################*/
.pflicht, .validierung {
  color: #ff0000 !important
}
