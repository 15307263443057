.mg-news-teaser {
  .headline {
    @include mg-sectiontitle();
  }
  .list-entries {

  }
  .list-entry {
    & > .ap {
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: $mg-space--xsmall;
      padding: $mg-space;
      .teaser-visual {
        margin: $mg-space * -1;
        margin-bottom: $mg-space;
        .image-outer-box {
          border: none;
        }
      }
      .teaser-body {
        a {
          display: flex !important;
          flex-direction: column;
          .teaser-date {
            order: 1;
            min-height: auto;
          }
          .teaser-title {
            order: 2;
            font-size: get-fontsize('xl');
            margin-bottom: 0.5em;
            color: get-color($mg-theme, 'dark');
          }
          .teaser-text {
            order: 3;
          }
        }
      }
      .teaser-link {
        position: relative;
        margin-top: auto;
        .btn {
          border-radius: $mg-space--xxsmall;
          padding: 0.5em 1.75em;
          &:hover,
          &:focus {
            background-color: get-color($mg-theme, 'dark');
          }
        }
      }
      &:hover,
      &:focus {
        box-shadow: 0 0 5px get-color($mg-theme, 'dark');
      }
    }
  }
}

.mg-news-teaser--intern {
  > div {
    display: flex;
    justify-content: flex-start;
    margin: 0 -15px;
    width: calc(100% + 30px);
  }
  .ap-teaser {
    width: 25%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: $mg-space--xsmall;
    padding: $mg-space;
    overflow: hidden;
    margin: 15px;
    .teaser-visual {
      margin: $mg-space * -1;
      margin-bottom: $mg-space;
      width: calc(100% + 100px);
      padding: 0;
      .image-outer-box {
        border: none;
      }
    }
    .teaser-body {
      a.fully {
        display: flex !important;
        flex-direction: column;
        .teaser-date {
          order: 1;
          min-height: auto;
        }
        .teaser-title {
          order: 2;
          font-size: get-fontsize('xl');
          margin-bottom: 0.5em;
          color: get-color($mg-theme, 'dark');
        }
        .teaser-text {
          order: 3;
        }
      }
    }
    .teaser-btn {
      position: relative;
      margin-top: auto;
      border-radius: $mg-space--xxsmall;
      padding: 0.5em 1.75em;
      &:hover,
      &:focus {
        background-color: get-color($mg-theme, 'dark');
      }
    }
    &:hover,
    &:focus {
      box-shadow: 0 0 5px get-color($mg-theme, 'dark');
    }
  }
}

.ap-detail-page .visual-text {
  font-weight: normal;
}

.mg-news-detail--back .btn {
  @include mg-button();
}
