$mg-container-padding: $mg-space--large;

body {
  background: get-color("general");
}

.container {
  width: 100% !important;
  @media (min-width: 768px) {
    & > .row {
      padding-left: $mg-container-padding;
      padding-right: $mg-container-padding;
    }
  }
}

.mg_content {
  .container {
   background-color: #fff;
   padding: $mg-space 0;
   .kategorien_bg {
     margin: 0 !important;
     padding: $mg-space;
   }
  }
  &--color {
    .container {
      background-color: get-color('general', 'xlight');
    }
  }
}

#apollo-info {
  display: none;
}
