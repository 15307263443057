.mg-title {
  .head,
  .headline {
    border-bottom: none;
    margin-top: $mg-space;
    margin-bottom: $mg-space--small;
    & > * {
      color: get-color($mg-theme, 'dark');
      border-bottom: none;
      font-size: get-fontsize('xl');
      font-weight: 600;
      margin: 0;
      padding: 0;
    }
  }
}
