.sidebar-nav { // allgemeines
  li a {
    font-family: 'Exo', sans-serif;
    &:not(.btn) {
      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .currentpage a {
    font-weight: 800;
  }
}

.head-navbar {
  @media screen and (min-width: $screen-md-min) {
    display: none;
  }
  background-color: get-color("general", "dark") !important;
  .navbar-collapse {
    background-color: get-color("general", "dark") !important;
  }
}

.mg_nav-main {
  margin-bottom: 0;
  .list-group {
    display: flex;
    justify-content: flex-end;
  }
  .list-group-item {
    a {
      padding: 0 0.5em 0.5em;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }
}

.mg_nav-categories {
  margin-bottom: $mg-space--xsmall;
  .list-group {
    display: flex;
    justify-content: flex-end;
  }
  .list-group-item {
    a {
      padding: 0 0.5em;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
    &.currentpage a {
      @include mg-triangle($mg-space--xsmall, $main-theme--dark);
      font-weight: 600;
      margin-bottom: (20px + $mg-space--xsmall) * -1;
    }
    // &:nth-of-type(1) a:hover {
    //   color: get-color("religion_spiritualitaet");
    // }
    // &:nth-of-type(2) a:hover {
    //   color: get-color("qualifikation_fortbildung");
    // }
    // &:nth-of-type(3) a:hover {
    //   color: get-color("leben_beziehungen");
    // }
    // &:nth-of-type(4) a:hover {
    //   color: get-color("kultur_kreativitaet");
    // }
    // &:nth-of-type(5) a:hover {
    //   color: get-color("gesellschaft_politik");
    // }
    // &:nth-of-type(6) a:hover {
    //   color: get-color("familien_kinder");
    // }
    // &:nth-of-type(7) a:hover {
    //   color: get-color("gesundheit_fitness");
    // }
    // &:nth-of-type(8) a:hover {
    //   color: get-color("online_lernen");
    // }
  }
}

.mg_subnav {
  .row {
    background-color: get-color($mg-theme, 'dark');
  }
  .ap-sidebar-nav {
    margin: 0
  }
  .sidebar-nav {
    display: flex;
    @media screen and (max-width: $screen-mobile-max) {
      flex-wrap: wrap;
    }
    .list-group-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-basis: 100%;
      border-radius: 5px;
      margin: 15px;
      background-color: get-color($mg-theme);
      &:hover, &:focus,
      &.currentpage {
        background-color: get-color($mg-theme);
        box-shadow: 0 0 5px get-color($mg-theme, 'dark');
        a {
          text-decoration: none;
          font-weight: 800;
        }
      }
      .nav-label {
        height: 100%;
        width: 100%;
        text-align: center;
        padding: 8px;
      }
    }
  }
}

/////

@mixin mg-buttonnav($theme) {
  background-color: get-color($theme);
  &::before {
    background-image: url('../forum_mg/img/#{get-icon($theme)}');
  }
  &:hover {
    box-shadow: 0 0 5px get-color($theme, 'dark');
  }
}

.mg-buttonnav {
  margin: 0 auto;
  @media (min-width: $screen-md-min) {
    max-width: 75%;
  }
  .list-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      width: 270px;
      padding: .25em;
      margin: $mg-space--small / 2;
      border-radius: $mg-space--xsmall;
      display: flex;
      flex-basis: calc(25% - #{$mg-space--small} - 10px);
      flex-shrink: 0;
      flex-grow: 1;
      justify-content: flex-start;
      align-items: center;
      background-color: get-color("general", "dark");
      a {
        flex-grow: 1;
        flex-basis: 66%;
        background: transparent;
        border: 0 !important;
        height: 100%;
        padding: 2rem;
        font-size: 1.7rem;
        font-weight: 600;
        color: #fff;
        &:hover, &:focus {
          text-decoration: none;
        }
      }
      &::before {
        flex-basis: 33%;
        content: '';
        display: inline-block;
        height: $mg-space--large;
        width: $mg-space--large;
        background-repeat: no-repeat;
        background-size: contain;
        flex-basis: $mg-space--large;
        margin: 1rem 0 1rem 2rem;
      }
      &:nth-of-type(1) {
        @include mg-buttonnav('religion_spiritualitaet');
      }
      &:nth-of-type(2) {
        @include mg-buttonnav('qualifikation_fortbildung');
      }
      &:nth-of-type(3) {
        @include mg-buttonnav('leben_beziehungen');
      }
      &:nth-of-type(4) {
        @include mg-buttonnav('interkulturalitaet_sprache');
      }
      &:nth-of-type(5) {
        @include mg-buttonnav('kultur_kreativitaet');
      }
      &:nth-of-type(6) {
        @include mg-buttonnav('gesellschaft_politik');
      }
      &:nth-of-type(7) {
        @include mg-buttonnav('familien_kinder');
      }
      &:nth-of-type(8) {
        @include mg-buttonnav('gesundheit_fitness');
      }
    }
  }
}
