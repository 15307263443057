@mixin mg-triangle($size: 20px, $color: $main-theme) {
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  // margin-bottom: $size * -1;
  &::after {
    content: '';
    width: 0;
    height: 0;
    align-self: center;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    clear: both;
    margin-top: $size;
    z-index: 999;
    margin-bottom: $size * -1;
  }
}
