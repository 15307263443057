$mg-logo-width: 175px;

.mg_head {
  margin-bottom: 0;
  .head {
    margin-bottom: 0;
  }
  .head-container .container {
    background-color: $main-theme--dark;
    padding-top: $mg-space--xsmall;
    .row {
      display: flex;
    }
  }
  .bis-logo {
    width: $mg-logo-width;
  }
  .bis-slider {
    width: calc(100% - #{$mg-logo-width}) !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }
}

.mg_subheader {
  .mg_subheader--row {
    background-color: $main-theme--dark;
    background:
      repeat top center / get-bgimage-scale($mg-theme) url('../forum_mg/img/#{get-bgimage($mg-theme)}'),
      radial-gradient($main-theme--light, $main-theme--dark 140%);
    padding: $mg-space;
    & > div[class^="col-"] > div:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0
    }
    .sitename {
      padding: 5rem;
      text-align: center;
      .head > * {
        color: #fff;
      }
    }
    .mg-title {
      text-align: center;
      .head > *{
        color: #fff;
        font-weight: 600;
      }
      .text > * {
        color: #fff;
        font-weight: 500;
        font-size: 1.75rem;
      }
    }
    .mg-text {
      margin: 0 auto;
      padding: 0 ($mg-space--small / 2);
      @media (min-width: $screen-md-min) {
        width: 75%;
      }
      .text {
        p,
        a {
          text-align: center;
          color: #fff;
          font-size: 1.5rem;
        }
        a {
          text-decoration: underline;
          &:hover, &:focus {
            font-weight: 600;
            line-height: 1;
          }
        }
      }
    }
  }
  .mg_subheader--content {
    max-width: 75%;
    margin: 5rem auto;
    .head > * {
      color: #fff;
      font-family: 'Exo', sans-serif;
      font-weight: 600;
      font-size: 3rem;
      margin-bottom: 1em;
      @media screen and (max-width: $screen-md-max) {
        font-size: 2rem;
      }
      @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        font-size: 3.2rem;
      }
    }
    .text > * {
      color: #fff;
      font-size: 1.5rem;
    }
    .ap-image {
      max-width: 13em;
      margin-left: auto;
    }
  }
  &_icon {
    width: 100%;
    min-width: 100px;
    margin-bottom: 0;
    @media screen and (min-width: $screen-md-min) {
      padding: 1rem 6rem 3rem;
    }
    &.bottomnull {
      padding-bottom: 0;
    }
  }
}
